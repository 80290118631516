@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Catamaran", sans-serif;
}

/* ===== Scrollbar Customization CSS ===== */
/* Firefox */
/* * {
    scrollbar-width: auto;
    scrollbar-color: #febc95 #ebebeb;
} */

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #ebebeb;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #febc95;
    border-radius: 10px;
    border: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #fe8740;
} */