/* CSS for background gradient animation */
.animated-bg {
    background: linear-gradient(135deg, #bbdefb, #c8e6c9, #ffecb3);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

/* Keyframes for background gradient animation */
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


/* Floating effect for the card */
.animated-card {
    animation: float 6s ease-in-out infinite;
}

@keyframes float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}
